import { createRoot } from "react-dom/client";
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import { App } from "./App";
import theme from './theme'

const container = document.getElementById("app");
const root = createRoot(container)
root.render(
    <ChakraProvider>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <App />
    </ChakraProvider>,
  );