import { Grid, Center, Stack, Box, Flex, Icon, Link, Heading } from "@chakra-ui/react";
import { MdGridOn } from "react-icons/md";

export function App() {
    return (<Grid
        height="100vh"
        placeItems="center"
        bg="whiteAlpha.500"
      >
        <Stack borderWidth='1px' borderRadius='lg' borderColor='gray.100' bg="white">
            <Center bg='gray.50'>
                <Heading p='2' as='h2' >Game of Life Editor</Heading >
            </Center>
            <Center p='2' >
                <Flex gap="2" align="center">
                <Link href="golr/gol-test.html"  _hover={{ bg: "blue.100", cursor: "pointer" }}>
                    <Stack spacing="0">
                        <Icon as={MdGridOn}  boxSize="48px"  />
                        <Center color="gray.500">
                          open
                        </Center>
                        
                    </Stack>
                    </Link>
                    <Box flex="1" color="gray.800">
                        <p> A game of life playground that doubles as level editor for a game in development</p>
                    </Box>
                </Flex>
            </Center>
        </Stack>
        
      </Grid>);
  }